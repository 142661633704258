.login {
	height: 100vh;
	width: 100vw;
	background-color: #333;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	overflow-y: hidden;
	/* font-family: "PT Sans",sans-serif; */
}



.login-logo {
	height: 10vh;
	margin-bottom: 10vh;
	transform: scale(0.8);
}

.login-container {
	background-color: white;
	padding: 5vh;
	width: 35vw;
	border-radius: 2%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
}

@media only screen and (max-width: 1200px){
    /* .login-container {
		font-size: 0.5rem;
	} */

	h3 {
		font-size: 0.3rem;
	}
}

@media only screen and (max-width: 800px){
    .login-container {
		font-size: 0.2rem;
	}
}


.login-container::-webkit-scrollbar {
	display: none;
  }

#login-email {
	width: 30vw;
}

#login-password {
	width: 30vw;
}

#forgot-email {
	width: 30vw;
}

#forgot-password {
	width: 30vw;
}

a {
	text-decoration: none;
}

#back {
	color: #efb758;
	padding: 0;
	margin-top: 5vh;
}

h6,
h5 {
	color: gray;
}

.sign-in-button {
	background-color: white;
	box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	border: 2px solid #efb758;
	padding: 3px 19px;
	line-height: 32px;
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: 50;
	color: #efb758;
	width: 10vw;
	margin-top: 20px;
}

.login-create {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
}
/****************************************************************/
/* Shutter In Vertical */
.hvr-shutter-in-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #efb758;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	border-radius: 20px;
}
.hvr-shutter-in-vertical:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	border-radius: 20px;
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.hvr-shutter-in-vertical:hover,
.hvr-shutter-in-vertical:focus,
.hvr-shutter-in-vertical:active {
	color: white;
}
.hvr-shutter-in-vertical:hover:before,
.hvr-shutter-in-vertical:focus:before,
.hvr-shutter-in-vertical:active:before {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
}

/**************************************************************/

.spinner {
	width: 100px;
	height: 50px;
}

.incorrect {
	color: red;
}

@media only screen and (max-width: 700px) {
	.sign-in-button {
		transform: scaleX(1.5);
	}

	.login-container {
		height: 65vh;
		width: 50vw;
	}
}

.forgot {
	cursor: pointer;
}

.forgot-send-button {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	transform: translateY(2vh);
}
