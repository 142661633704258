@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
	background-color: #333;
	text-align: center;
}

* {
	box-sizing: border-box;
}

body {
	/* background-color: rgba(51, 51, 51, 0.459); */
	margin: 0;
	padding: 0;
	height: 100vh;
	overflow-y: hidden;
}

.login-logo {

}

.login {
	height: 100vh;
	width: 100vw;
	background-color: #333;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	overflow-y: hidden;
	/* font-family: "PT Sans",sans-serif; */
}



.login-logo {
	height: 10vh;
	margin-bottom: 10vh;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}

.login-container {
	background-color: white;
	padding: 5vh;
	width: 35vw;
	border-radius: 2%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
}

@media only screen and (max-width: 1200px){
    /* .login-container {
		font-size: 0.5rem;
	} */

	h3 {
		font-size: 0.3rem;
	}
}

@media only screen and (max-width: 800px){
    .login-container {
		font-size: 0.2rem;
	}
}


.login-container::-webkit-scrollbar {
	display: none;
  }

#login-email {
	width: 30vw;
}

#login-password {
	width: 30vw;
}

#forgot-email {
	width: 30vw;
}

#forgot-password {
	width: 30vw;
}

a {
	text-decoration: none;
}

#back {
	color: #efb758;
	padding: 0;
	margin-top: 5vh;
}

h6,
h5 {
	color: gray;
}

.sign-in-button {
	background-color: white;
	box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	border: 2px solid #efb758;
	padding: 3px 19px;
	line-height: 32px;
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: 50;
	color: #efb758;
	width: 10vw;
	margin-top: 20px;
}

.login-create {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	position: relative;
}
/****************************************************************/
/* Shutter In Vertical */
.hvr-shutter-in-vertical {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	background: #efb758;
	transition-property: color;
	transition-duration: 0.3s;
	border-radius: 20px;
}
.hvr-shutter-in-vertical:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	border-radius: 20px;
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}
.hvr-shutter-in-vertical:hover,
.hvr-shutter-in-vertical:focus,
.hvr-shutter-in-vertical:active {
	color: white;
}
.hvr-shutter-in-vertical:hover:before,
.hvr-shutter-in-vertical:focus:before,
.hvr-shutter-in-vertical:active:before {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
}

/**************************************************************/

.spinner {
	width: 100px;
	height: 50px;
}

.incorrect {
	color: red;
}

@media only screen and (max-width: 700px) {
	.sign-in-button {
		-webkit-transform: scaleX(1.5);
		        transform: scaleX(1.5);
	}

	.login-container {
		height: 65vh;
		width: 50vw;
	}
}

.forgot {
	cursor: pointer;
}

.forgot-send-button {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	-webkit-transform: translateY(2vh);
	        transform: translateY(2vh);
}

.side-drawer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	height: 100vh;
	background: #333333;

	position: relative;
	top: 0;
	left: 0;
	z-index: 200;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	/* transform: translateX(100%); */
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.closed {
	width: 96px;
	height: 100%;
}

.toggle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0;
	width: 24px;
	height: 100%;
	padding: 0 0.5px;
	border-left: 1px solid #585858;
	background-color: #333333;
}

.toggle:hover {
	background-color: white;
}

.spanner {
}

.navigation {
	height: 100vh;
	/* display: flex;
	flex-direction: column; */
	/* justify-content: flex-start; */
	/* align-items: stretch; */
	/* justify-content: stretch; */
	/* text-align: center; */
	/* margin: 0 auto; */
}

ul {
	width: 20vw;
	list-style-type: none;
}

.list-item {
	display: flex;

	justify-content: center;
	align-items: center;

	/* border-bottom: 1px solid #585858; */
	/* padding: 0 10px 0 18px; */
	height: 64px;
	transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
		box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	font-family: "Montserrat", sans-serif;
	font-size: 17px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.014);
	/* display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center; */
	cursor: pointer;
	transition: 0.2s;
}
/* 
.sublist-item {
	font-family: "Montserrat", sans-serif;
	font-size: 14px !important;
	font-weight: 500;
} */

.Mui-selected {
	background-color: white;
	color: black;
}
.list-item:hover {
	background-color: #585858 !important;
}

.active {
	color: black;
	background-color: white;
}

.branding {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin: 0;
	padding: 0;
	-webkit-transform: translateX(-20px);
	        transform: translateX(-20px);

	/* margin: 20px 0 16px 85%;
    width: 35px;
    overflow: hidden;
	transition: margin 0.25s ease; */
}

.log-out {
	cursor: pointer;
	font-family: Montserrat,sans-serif;
	background-color: #efb758;
	color: white;
	padding: 0 14px!important;
    min-width: 0;
    line-height: 30px;
    font-size: 13px;
	font-weight: 700;
	border-radius: 20px!important;
	border-width: 2px!important;
	margin-top: 10vh;
	width: 10vw;
}

.branding img {
	padding-left: 24px;
	/* display:flex;
    flex-direction: row;
    justify-content: flex-start; */
}

.spanner {
	-webkit-transform: translateY(40vh);
	        transform: translateY(40vh);
}

.panel {
	color: grey;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.MuiButtonBase-root {
	background-color: black;
}

.MuiAccordionSummary-root {
	background-color: black;
}

.MuiListItem-root {
	width: 18vw !important;
}

.Mui-selected {
	background-color: #585858 !important;
}

.MuiListItem-divider {
	border-bottom: 1px solid #585858 !important;
}

.MuiListItemText-root {
	flex: 1 1;
	align-items: center;
	justify-content: center;
}

/* .list-text {
	background-color: yellow;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
} */

.MuiListItem-alignItemsFlexStart {
	align-items: center !important;
}

.MuiSvgIcon-root {
	color: #666666 !important;
}

.MuiListItemIcon-root {
	fill: none;
}

* {
	-ms-overflow-style: none;
}
::-webkit-scrollbar {
	display: none;
}

.branding {
	display: flex;
	flex-direction: column;
}

.myClassname::-webkit-scrollbar {
    display: none;
  }
