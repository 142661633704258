.App {
	background-color: #333;
	text-align: center;
}

* {
	box-sizing: border-box;
}

body {
	/* background-color: rgba(51, 51, 51, 0.459); */
	margin: 0;
	padding: 0;
	height: 100vh;
	overflow-y: hidden;
}

.login-logo {

}
