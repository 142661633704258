* {
	-ms-overflow-style: none;
}
::-webkit-scrollbar {
	display: none;
}

.branding {
	display: flex;
	flex-direction: column;
}
