@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.side-drawer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	height: 100vh;
	background: #333333;

	position: relative;
	top: 0;
	left: 0;
	z-index: 200;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	/* transform: translateX(100%); */
	transition: transform 0.3s ease-out;
}

.closed {
	width: 96px;
	height: 100%;
}

.toggle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0;
	width: 24px;
	height: 100%;
	padding: 0 0.5px;
	border-left: 1px solid #585858;
	background-color: #333333;
}

.toggle:hover {
	background-color: white;
}

.spanner {
}

.navigation {
	height: 100vh;
	/* display: flex;
	flex-direction: column; */
	/* justify-content: flex-start; */
	/* align-items: stretch; */
	/* justify-content: stretch; */
	/* text-align: center; */
	/* margin: 0 auto; */
}

ul {
	width: 20vw;
	list-style-type: none;
}

.list-item {
	display: flex;

	justify-content: center;
	align-items: center;

	/* border-bottom: 1px solid #585858; */
	/* padding: 0 10px 0 18px; */
	height: 64px;
	transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
		box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	font-family: "Montserrat", sans-serif;
	font-size: 17px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.014);
	/* display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center; */
	cursor: pointer;
	transition: 0.2s;
}
/* 
.sublist-item {
	font-family: "Montserrat", sans-serif;
	font-size: 14px !important;
	font-weight: 500;
} */

.Mui-selected {
	background-color: white;
	color: black;
}
.list-item:hover {
	background-color: #585858 !important;
}

.active {
	color: black;
	background-color: white;
}

.branding {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin: 0;
	padding: 0;
	transform: translateX(-20px);

	/* margin: 20px 0 16px 85%;
    width: 35px;
    overflow: hidden;
	transition: margin 0.25s ease; */
}

.log-out {
	cursor: pointer;
	font-family: Montserrat,sans-serif;
	background-color: #efb758;
	color: white;
	padding: 0 14px!important;
    min-width: 0;
    line-height: 30px;
    font-size: 13px;
	font-weight: 700;
	border-radius: 20px!important;
	border-width: 2px!important;
	margin-top: 10vh;
	width: 10vw;
}

.branding img {
	padding-left: 24px;
	/* display:flex;
    flex-direction: row;
    justify-content: flex-start; */
}

.spanner {
	transform: translateY(40vh);
}

.panel {
	color: grey;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.MuiButtonBase-root {
	background-color: black;
}

.MuiAccordionSummary-root {
	background-color: black;
}

.MuiListItem-root {
	width: 18vw !important;
}

.Mui-selected {
	background-color: #585858 !important;
}

.MuiListItem-divider {
	border-bottom: 1px solid #585858 !important;
}

.MuiListItemText-root {
	flex: 1;
	align-items: center;
	justify-content: center;
}

/* .list-text {
	background-color: yellow;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
} */

.MuiListItem-alignItemsFlexStart {
	align-items: center !important;
}

.MuiSvgIcon-root {
	color: #666666 !important;
}

.MuiListItemIcon-root {
	fill: none;
}
